
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CarsService, { CarsServiceS } from '../../cars.service';

@Component({
    components: { CustomSelect },
})
export default class DoorsFilter extends Vue {
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @inject(CarsServiceS) private carsService!: CarsService;

    get items(): Item[] {
        const { doorsFilter } = this.carsFiltersService;

        return doorsFilter.map(({ value, disabled }) => ({
            name: String(value),
            value,
            disabled,
        }));
    }

    get currentValue() {
        return this.carsService.storeState.settings.doors;
    }
    set currentValue(value) {
        if (value) {
            this.carsService.storeState.settings.doors = value;
        }
    }
}
