
import { Prop, Vue, Component } from 'vue-property-decorator';
import { Popover, Dropdown, DropdownMenu } from 'element-ui';

export interface CascaderOptions {
    label: string,
    value: string | number,
    items?: CascaderOptions[];
}

@Component({
    components: {
        'el-popover': Popover,
        'el-dropdown': Dropdown,
        'el-dropdown-menu': DropdownMenu,
    },
})
export default class CustomCascader extends Vue {
    @Prop({
        required: true,
        type: Array as () => CascaderOptions[],
    })
    private items!: CascaderOptions[];

    @Prop({
        required: false,
        type: Array as () => string[],
    })
    private value!: any[];

    @Prop({
        required: false,
        type: String,
    })
    private label!: string;

    @Prop({
        type: String,
        required: false,
    })
    private title?: string;

    @Prop({
        type: String,
        required: false,
    })
    private selectText?: string;

    @Prop({
        type: Number,
        required: false,
        default: () => 256,
    })
    private width?: string;

    disabled = false;

    private isExpanded: boolean = false;

    clickOutside(e: MouseEvent) {
        const ref = this.$refs.multiSelect as HTMLDivElement;
        if (ref && (ref !== e.target && !ref.contains(e.target as Node | null))) {
            this.isExpanded = false;
            document.body.removeEventListener('click', this.clickOutside);
        }
    }

    hide() {
        this.isExpanded = false;
    }

    handleSelectClick() {
        this.isExpanded = !this.isExpanded;
        if (this.isExpanded) {
            document.body.addEventListener('click', this.clickOutside, true);
        } else {
            document.body.removeEventListener('click', this.clickOutside, true);
        }
    }

    handleOptionClick(parent: any, child: any) {
        this.disabled = true;
        this.hide();
        this.$emit('input', [parent, child]);
    }

    isSelectedPerant(item: CascaderOptions) {
        return (this.value[0] && item.value === this.value[0]);
    }

    isSelectedChild(parent: CascaderOptions, child: CascaderOptions) {
        return (parent.value === this.value[0] && child.value === this.value[1]);
    }
}
