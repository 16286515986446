
import { Component, Prop, Vue } from 'vue-property-decorator';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';

@Component({
    components: {
        DateDocumentFilter,
    },
})
export default class CarHeader extends Vue {
    @Prop({
        required: false,
        type: Boolean,
        default: false,
    })
    private showTooltip!: boolean;

    infoTooltip: boolean = false;
}
