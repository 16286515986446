
import { Component, Vue, Watch } from 'vue-property-decorator';
import { inject } from '@/inversify';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import CarsService, { CarsServiceS } from '../../cars.service';

interface IOption {
    name: string;
    value: string;
    disabled: boolean;
}

@Component({
    components: { CustomMultiSelect },
})
export default class CompetitorsFilter extends Vue {
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;
    @inject(CarsServiceS) private carsService!: CarsService;

    @Watch('options')
    onOptionsChange(opts: IOption[]) {
        this.currentValue = opts;
    }

    get currentValue() {
        const { competitors } = this.carsService.storeState.settings;

        if (!competitors) {
            return [];
        }

        const compFilter = new Set(this.carsFiltersService.competitorsFilter);
        return competitors.map(item => ({
            name: item,
            value: item,
            disabled: !compFilter.has(item),
        })) || [];
    }

    set currentValue(value) {
        const { dataSource } = this.carsService.storeState.settings;
        if (dataSource) {
            this.carsService.storeState.settings.dataSourceCompetitors[dataSource] = value.map(item => item.value);
        }
        this.fleetFiltersService.saveCompetitors(value.map(item => item.value));
    }

    get options() {
        this.carsService.storeState.settings.currentDocumentCompetitors = this.carsFiltersService.competitorsFilter;
        const compFilter = new Set(this.carsService.storeState.settings.currentDocumentCompetitors);
        return this.carsFiltersService.allCompetitors
            .map(val => ({
                value: val,
                name: val,
                disabled: !compFilter.has(val),
            })).sort((a, b) => {
                if (a.value < b.value) {
                    return -1;
                }
                return 1;
            });
    }
}
