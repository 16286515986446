
import { Component, Vue } from 'vue-property-decorator';
import PickUpCityFilter from '@/modules/cars/components/cars-filters/pick-up-city-filter.vue';
import DataSourceFilter from '@/modules/cars/components/cars-filters/data-source-filter.vue';
import DataSourceBrokerFilter from '@/modules/cars/components/cars-filters/data-source-broker-filter.vue';
import BrokersCompetitorsFilter from '@/modules/cars/components/cars-filters/brokers-competitors-filter.vue';
import LorFilter from '@/modules/cars/components/cars-filters/lor-filter.vue';
import PosFilter from '@/modules/cars/components/cars-filters/pos-filter.vue';
import PriceTypeFilter from '@/modules/cars/components/cars-filters/price-type-filter.vue';
import DoorsFilter from '@/modules/cars/components/cars-filters/doors-filter.vue';
import TransmissionFilter from '@/modules/cars/components/cars-filters/transmission-filter.vue';
import MileageFilter from '@/modules/cars/components/cars-filters/mileage-filter.vue';
import CompetitorsFilter from '@/modules/cars/components/cars-filters/competitors-filter.vue';
import PaymentTermsFilter from '@/modules/cars/components/cars-filters/payment-terms-filter.vue';
import CountryFilter from '@/modules/cars/components/cars-filters/country-filter.vue';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { inject } from '@/inversify';
import CarRatesAnalysisBtn from '@/modules/cars/analysis/car-rate-analysis-btn.vue';
import CarFuelTypeFilter from '@/modules/cars/components/cars-filters/car-fuel-type-filter.vue';
import CarClassesFilter from './cars-filters/car-classes-filter.vue';
import CountryClusterFilter from './cars-filters/country-cluster-filter.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '../cars-filters.service';
import { BROKER } from '../constants/data-source-mode.constant';
import { BROKER_TO_BROKER } from '../constants/car-filter-types.constant';

@Component({
    components: {
        CarFuelTypeFilter,
        PickUpCityFilter,
        DataSourceFilter,
        LorFilter,
        PosFilter,
        PriceTypeFilter,
        DoorsFilter,
        TransmissionFilter,
        MileageFilter,
        CompetitorsFilter,
        PaymentTermsFilter,
        CountryFilter,
        CarClassesFilter,
        CountryClusterFilter,
        DataSourceBrokerFilter,
        BrokersCompetitorsFilter,
        CarRatesAnalysisBtn,
    },
})
export default class CarActions extends Vue {
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @inject(UserServiceS) private userService!: UserService;

    get currentPath() {
        return this.$route.path;
    }

    get currentRouteName() {
        return this.$route.name!;
    }

    get isAvgPrice() {
        return this.carsService.storeState.settings.isAvgPrice;
    }

    get clusterMode() {
        return this.carsFiltersService.storeState.settings.features.clusterMode;
    }

    get isBroker() {
        return this.carsFiltersService.chainMode === BROKER;
    }

    get showBrokersCompetitors() {
        const { currentDataSourceMode } = this.carsService;
        return this.isBroker && currentDataSourceMode === BROKER_TO_BROKER;
    }

    get isAnalysisMode() {
        return this.currentRouteName.includes('analysis');
    }

    get isLoading() {
        return this.carsService.isLoading;
    }
}
