
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '../../cars-filters.service';
import CarsService, { CarsServiceS } from '../../cars.service';

@Component({
    components: { CustomSelect },
})
export default class DataSourceFilter extends Vue {
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @inject(CarsServiceS) private carsService!: CarsService;

    get options(): Item[] {
        const { availableDataSources } = this.carsFiltersService;

        if (!availableDataSources) {
            return [];
        }

        if (!this.currentValue || !availableDataSources.find(dataSource => dataSource === this.currentValue)) {
            const [defaultValue] = availableDataSources;
            this.currentValue = defaultValue;
        }

        return availableDataSources.map(value => ({
            name: value,
            value,
        }));
    }

    get currentValue() {
        return this.carsService.storeState.settings.dataSource;
    }
    set currentValue(value) {
        if (value) {
            this.carsService.storeState.settings.dataSource = value;
        }
    }
}
