
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import GraphLegendIcon from '@/modules/common/components/ui-kit/graph-legend-icon.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import PROVIDER_COLORS from '@/modules/common/constants/providers.colors.constant';
import { BRAND_AS_BROKER_ANY } from '@/modules/cars/constants/car-filter-types.constant';
import { BROKER } from '../../constants/data-source-mode.constant';

export interface ICompanyLabel {
    name: string | null;
    color: string;
}

@Component({
    components: {
        GraphLegendIcon,
    },
})
export default class RatesGraphCarsLabel extends Vue {
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @inject(UserServiceS) private userService!: UserService;

    get carsGraphColor() {
        return this.carsService.carsGraphColor;
    }

    get currentCompany(): ICompanyLabel | null {
        const { currentCompany } = this.userService;

        if (!currentCompany) {
            return null;
        }

        return {
            name: currentCompany,
            color: CURRENT_HOTEL_GRAPH_COLOR,
        };
    }

    get isBrokerToBroker() {
        const { isBrokerToBroker } = this.carsService;
        return isBrokerToBroker;
    }

    get competitors(): ICompanyLabel[] | null {
        const { isBrokerToBroker, brokersCompetitors } = this.carsService;
        const { competitorsFilter } = this.carsFiltersService;

        let competitorsList = [...competitorsFilter];
        if (isBrokerToBroker) {
            competitorsList = [...(brokersCompetitors || [])];
        }

        return this.carsGraphColor && Object.keys(this.carsGraphColor)
            .map(company => ({
                name: company,
                color: this.carsGraphColor[company],
            }))
            .filter(item => competitorsList.includes(item.name))
            .filter(item => this.carsFiltersService.isProviderAvailableInCurrentPOS(item.name));
    }

    get getIconColor() {
        return this.userService.currentCompany ? PROVIDER_COLORS[this.userService.currentCompany] : '#555555';
    }

    get currentBrand() {
        const { chainMode } = this.carsFiltersService;

        if (chainMode !== BROKER) {
            return '';
        }

        const { currentBrandAsBroker } = this.carsService;
        const [, brand] = currentBrandAsBroker.split(',');
        return (currentBrandAsBroker === BRAND_AS_BROKER_ANY) ? '(All)' : `(${brand})`;
    }
}
