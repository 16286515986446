
import { Component, Vue, Prop } from 'vue-property-decorator';
import CarFleetAvailabilityTooltip from '@/modules/cars/components/car-fleet-availability-tooltip.vue';
import CarTogglePrices from '@/modules/cars/components/car-toggle-prices.vue';
import Demand from '@/modules/common/components/ui-kit/demand.vue';
import type Day from '@/modules/common/types/day.type';

@Component({
    components: {
        Demand,
        CarFleetAvailabilityTooltip,
        CarTogglePrices,
    },
})
export default class RatesGraphFleetAvailability extends Vue {
    @Prop({
        type: Array,
    })
    dayStatistics!: {
        countCars: number,
        totalCars: number,
        percent: number,
    }[];

    @Prop({
        type: Object,
        default: 0,
    })
    average!: {
        countCars: number,
        totalCars: number,
        percent: number,
    };

    @Prop({
        type: String,
    })
    selectedDay!: Day;

    @Prop({
        type: Number,
        required: false,
        default: 0,
    })
    leftOffset!: number;

    get statistic() {
        return this.dayStatistics;
    }

    getFleetAvailabilityTooltip(item: {
        countCars: number,
        totalCars: number,
        percent: number,
    }) {
        return item.percent ? `Fleet Availability\n${item.countCars}\u00A0out\u00A0of\u00A0${item.totalCars}\u00A0(${item.percent}%)` : 'N/A';
    }
}
