
import { Component, Vue, Watch } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import CarsFiltersService, { CarsFiltersServiceS } from '../../cars-filters.service';
import CarsService, { CarsServiceS } from '../../cars.service';

@Component({
    components: { CustomSelect },
})
export default class PosFilter extends Vue {
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    @Watch('carsService.storeState.settings.pickUpCityCode')
    onCityChange(locationCode: number) {
        const locationDefaultPOS = this.carsFiltersService.settings.defaultLocationsPOS[locationCode];
        if (locationDefaultPOS) {
            this.currentValue = locationDefaultPOS;
        }
    }

    get items(): Item[] {
        const { availablePos } = this.carsFiltersService;

        if (!availablePos) {
            return [];
        }

        if (!this.currentValue || !availablePos.find(pos => pos === this.currentValue)) {
            const [defaultPos] = availablePos;
            this.currentValue = defaultPos;
        }

        return availablePos.map(value => ({
            name: value,
            value,
        }));
    }

    get currentValue() {
        return this.carsService.storeState.settings.pos;
    }
    set currentValue(value) {
        if (value) {
            this.carsSharedService.savePos(value);
        }
    }
}
