
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CustomMultiSelect, { Item } from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import _ from 'lodash';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import CarsFiltersService, { CarsFiltersServiceS } from '../../cars-filters.service';
import CarsService, { CarsServiceS } from '../../cars.service';

@Component({
    components: { CustomMultiSelect },
})
export default class BrokersCompetitorsFilter extends Vue {
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;

    get options(): Item[] {
        const { availableDataSources, carProvidersDisplayNamesMap } = this.carsFiltersService;
        const { chainName } = this.userService;
        const { dataSourcesFromDoc } = this.carsService;

        if (!availableDataSources) {
            return [];
        }

        let brokers = availableDataSources.filter(item => ![chainName, 'Brand'].includes(item));

        if (dataSourcesFromDoc.length) {
            brokers = _.intersection(brokers, dataSourcesFromDoc);
        }

        const data = brokers.map(value => ({
            name: _.get(carProvidersDisplayNamesMap, value),
            value,
        }));

        return data;
    }

    get currentValue() {
        const { carProvidersDisplayNamesMap } = this.carsFiltersService;
        if (!this.carsService.storeState.settings.brokersCompetitors) {
            return null;
        }

        return this.carsService.storeState.settings.brokersCompetitors.map(value => ({
            name: _.get(carProvidersDisplayNamesMap, value),
            value,
        }));
    }

    set currentValue(value) {
        if (value) {
            this.carsService.storeState.settings.brokersCompetitors = value.map(item => item.value);
        }
    }
}
