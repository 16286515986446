
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';

import CarActions from '@/modules/cars/components/car-actions.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import CarHeader from '@/modules/cars/components/car-header.vue';
import CarRatesTooltip from '@/modules/cars/components/car-rates-tooltip.vue';
import RatesGraphFleetAvailability from '@/modules/cars/components/rates-graphs/rates-graph-fleet-availability.vue';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import RatesGraphTypeChanger from '../components/rates-graphs/rates-graph-type-changer.vue';
import RatesGraph from '../components/rates-graphs/rates-graph.vue';
import RatesGraphCars from '../components/rates-graphs/rates-graph-cars.vue';
import RatesGraphLabel from '../components/rates-graphs/rates-graph-label.vue';
import RatesGraphCarsLabel from '../components/rates-graphs/rates-graph-cars-label.vue';

@Component({
    components: {
        CarActions,
        PageWrapper,
        CarHeader,
        RatesGraphTypeChanger,
        RatesGraph,
        RatesGraphCars,
        RatesGraphLabel,
        RatesGraphFleetAvailability,
        RatesGraphCarsLabel,
        CarRatesTooltip,
    },
})
export default class CarRatesGraphPage extends Vue {
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;

    @inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    isCarGraph: boolean = false;

    handleChange(value: boolean) {
        this.isCarGraph = value;
    }

    mounted() {
        this.eventsManagerService.saveIsLoadEventByPOS(true);
    }
    beforeDestroy() {
        this.eventsManagerService.saveIsLoadEventByPOS(false);
    }

    get dayStatistics() {
        return this.documentFiltersService.days
            .map(day => {
                const percent = this.carsService.occupancy(day);
                const { totalCars, countCars } = this.carsService.fleetAvailability(day);
                return {
                    countCars,
                    totalCars,
                    percent,
                };
            });
    }

    get average() {
        const daysCount = this.documentFiltersService.days.length;
        let availableDaysCount = 0;
        const res = this.dayStatistics.reduce((cur, {
            countCars,
            totalCars,
            percent,
        }, i) => {
            if (percent) {
                availableDaysCount += 1;
            }
            return {
                countCars: ((countCars || 0) + cur.countCars) / (i === daysCount - 1 ? availableDaysCount : 1),
                totalCars: ((totalCars || 0) + cur.totalCars) / (i === daysCount - 1 ? availableDaysCount : 1),
                percent: ((percent || 0) + cur.percent) / (i === daysCount - 1 ? availableDaysCount : 1),
            };
        }, {
            countCars: 0,
            totalCars: 0,
            percent: 0,
        });
        return {
            countCars: Math.round(res.countCars),
            totalCars: Math.round(res.totalCars),
            percent: Math.round(res.percent),
        };
    }
}
